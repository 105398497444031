/* pop up */

document.addEventListener("DOMContentLoaded", function () {
  const popup = document.getElementById("popup");
  const closeButton = document.querySelector(".popup-close");

  // Function to show the popup
  function showPopup() {
    if (document.cookie.indexOf("popupShown=true") === -1) {
      setTimeout(() => {
        popup.style.display = "flex";
        popup.style.visibility = "visible";
      }, 2000);
      document.cookie = "popupShown=true";
    } 
    
  }

  // Function to close the popup
  function closePopup() {
    popup.style.display = "none";
  }

  // Show the popup when the page loads
  showPopup();

  // Close the popup when the close button is clicked
  closeButton.addEventListener("click", closePopup);
});